import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import { ErrorMessage } from "formik";
import { Link } from "react-router-dom";
function PostcodeLookup(props) {
    var _a;
    const { placeholder, field, form: { errors }, } = props;
    const valid = (_a = (field.value !== "" && !(errors[field.name]))) !== null && _a !== void 0 ? _a : false;
    /* Step 1: PostcodeLookup */
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "form__group form__group--inline", children: [_jsx("input", Object.assign({}, field, { type: "text", placeholder: placeholder, value: `${field.value}`, className: "form__control" })), _jsx(Link, { to: "/bedrooms", className: `btn btn--next ${valid ? "" : "disabled"}`, children: "Search" })] }), _jsx(ErrorMessage, { component: "div", className: "form__error", name: field.name })] }));
}
export default PostcodeLookup;
